const fetchJson = (url, options) => {
	let headers = {
		'Authorization': localStorage.getItem('api_token'),
	}

	if (options.method != 'GET')
		headers['Content-Type'] = 'application/json';

	return fetch(url, {...options, headers: headers})
		.then((r) => {
			if(!r.ok) throw new Error(r.statusText)
			return r.json()
	})
}

const fetchVoid = (url, options) => fetch(url, {...options, headers: {
	...options.headers,
	'Authorization': localStorage.getItem('api_token'),
}}).then((r) => {
	if(!r.ok) throw new Error(r.statusText)
	return r.ok
})

export {
	fetchJson,
	fetchVoid,
}
import React from 'react';
import logo from './logo.svg';
import './App.css';

import {Router} from "@reach/router"
import Signin from './signin/signin'
import Uploader from './upload/uploader'
import ThankYou from './upload/thank-you'

function App() {
  return (
    <div className="app">
      <Router>
        <Signin path="/"/>
        <Uploader path="/upload"/>
        <ThankYou path="/thank-you"/>
      </Router>
    </div>
  );
}

export default App;

import React, { useEffect, useState, useRef } from 'react'
import {Button, Container, CssBaseline, Typography, TextField, makeStyles, Avatar} from '@material-ui/core'
import DoneIcon from '@material-ui/icons/Done'
import Uppy from '@uppy/core'
import AwsS3 from '@uppy/aws-s3-multipart'
import GoldenRetriever from '@uppy/golden-retriever'
import {Dashboard} from '@uppy/react'
import '@uppy/core/dist/style.css'
import '@uppy/drag-drop/dist/style.css'
import '@uppy/dashboard/dist/style.css'

import {navigate} from '@reach/router'
import Authentication from '../Authentication'
import MediaService from '../MediaService'

import './uploader.styl'

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function Uploader(props) {
  const auth = Authentication.getInstance()
  const classes = useStyles()

  function signOut() {
    auth.signOut()
    navigate('/')
  }

  return <div className="uploader">
    <header className="uploader-header">
      <Button variant="outlined" color="default" onClick={signOut}>Sign Out</Button>
    </header>

    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <DoneIcon/>
        </Avatar>
        <Typography component="h1" variant="h5">
          Thank You!
        </Typography>
        <Typography component="p">
          Your files were received.
        </Typography>
      </div>
      <br/><br/>
      <Button variant="contained" color="primary" fullWidth
        onClick={() => navigate('/upload')}>
        Upload More
      </Button>
    </Container>
  </div>
}

import {fetchJson} from './http'

export default (function() {
	let instance: any

	function signIn(email, password) {
		const body = JSON.stringify({authentication: {email, password}})
		return fetchJson(`${process.env.REACT_APP_AUTH_BASE_URL}/authenticate`, {method: 'POST', body}).then((json) => {
			localStorage.setItem('api_token', json.token)
			return json.token
		}).catch(console.error)
	}

	function signOut() {
		localStorage.removeItem('api_token')
	}

	function init(options) {
		instance = {
			signIn,
			signOut,
			token: () => localStorage.getItem('api_token'),
			fetchJson,
		}

		return instance
	}

	return {
		getInstance: (options = {}) => instance ? instance : init(options)
	}
})()
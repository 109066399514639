import React, { useEffect, useState, useRef } from 'react'
import {Button, Container, CssBaseline, Typography, TextField, Link} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import Uppy from '@uppy/core'
import AwsS3 from '@uppy/aws-s3-multipart'
import GoldenRetriever from '@uppy/golden-retriever'
import {Dashboard} from '@uppy/react'
import '@uppy/core/dist/style.css'
import '@uppy/drag-drop/dist/style.css'
import '@uppy/dashboard/dist/style.css'

import {navigate} from '@reach/router'
import Authentication from '../Authentication'
import LegacyApi from '../LegacyApi'
import {Project} from '../LegacyApi'
import MediaService from '../MediaService'

import './uploader.styl'

export default function Uploader(props) {
  const defaultUploadName = 'Aerial HD';
  const auth = Authentication.getInstance()
  useEffect(() => {
    auth.token() == null && navigate('/')
    console.log(auth.token())
  }, [auth])

  const inputRef = useRef<HTMLInputElement>()
  const [uploadToken, setUploadToken] = useState(Math.random().toString(36).substr(2, 5))
  const [uploadName, setUploadName] = useState(defaultUploadName)
  const [projects, setProjects] = useState<Project[]>([]);
  const [projectId, setProjectId] = useState<number>();
  const [selectedProject, setSelectedProject] = useState<Project>(null);
  
  const uploadCategories = [
    'Aerial HD',
    'Aerial 8-shots',
    'Terrestrial HD',
    'Other',
  ];

  useEffect(() => {
    const fetchProjects = async () => {
      let projects = await new LegacyApi().getProjects(112);
      projects[0].selected = true;
      setProjects(projects.filter(project => project.status < 2));
    }

    fetchProjects();
  }, [])

  useEffect(() => {
    if (projects && projectId) {
      const project = projects.find(project => project.id == projectId)
      projects.forEach(project => {
        project.selected = (project.id == projectId);
      });
      // project.selected = true;
      // console.log(project)
      setSelectedProject(project)
    }
  }, [projects, projectId]);

  // useEffect(() => {
  //   if (projects)
  //     projects.find(p => p.id == projectId).selected = true;
  // }, [projectId])

  /* Assign Upload name and token from url QS parameters */
  useEffect(() => {
    const uid = new URL(document.location.href).searchParams?.get('uid')
    if(!uid) return
    let idx = uid.lastIndexOf('_')
    setUploadToken(uid.substring(idx + 1, uid.length))
    let remaining = uid.substring(0, idx)
    idx = remaining.lastIndexOf('_')
    setUploadName(uid.substring(idx + 1, remaining.length))
    remaining = remaining.substring(0, idx)
    console.log(remaining);
    idx = remaining.lastIndexOf('_')
    console.log(remaining.substring(idx + 1, remaining.length))
    setProjectId(parseInt(remaining.substring(idx + 1, remaining.length)))
    uppy.setOptions({id: uploadToken})

    console.log(`Project: ${projectId}, upload: ${uploadName}, token: ${uploadToken}`)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    console.log('Changed...')
  }, [selectedProject])

  /* Update QS parameters as upload name changes */
  useEffect(() => {
    const url = new URL(document.location.href)
    url.searchParams.set('uid', `${selectedProject?.location.name}_${selectedProject?.id}_${uploadName}_${uploadToken}`)
    navigate(url, {replace: true})
    if(uploadName === defaultUploadName && inputRef.current) {
      inputRef.current.select()
    }
  }, [selectedProject, uploadName, uploadToken, inputRef])

  const uppy = Uppy({
    id: uploadToken,
    restrictions: {maxNumberOfFiles: 500},
    autoProceed: true,
    debug: true,
  })
  useEffect(() => {uppy.setMeta({type: 'avatar', uploadName, projectId: selectedProject?.id})}, [uppy, uploadName, selectedProject])

  const [uploadComplete, setUploadComplete] = useState(false)
  uppy.on('cancel-all', () => setUploadComplete(false))
  uppy.on('reset-progress', () => setUploadComplete(false))
  uppy.on('complete', () => setUploadComplete(true))

  uppy.use(AwsS3, {
    limit: 5,
    companionUrl: process.env.REACT_APP_MEDIA_UPLOAD_COMPANION,
    companionHeaders: {
      Authorization: auth.token(),
      'uppy-auth-token': auth.token(),
    },
  })

  uppy.use(GoldenRetriever, {serviceWorker: true})
  // if('serviceWorker' in navigator) {
  //   navigator.serviceWorker.register('/sw.js').then((registration) => {
  //     console.log('ServiceWorker registration successful with scope: ', registration.scope)
  //   }).catch((error) => {
  //     console.error('Registration failed with', error)
  //   })
  // }

  function signOut() {
    auth.signOut()
    navigate('/')
  }

  async function callRoger() {
    const files = Object.values(uppy.getState()?.files || {}).map((f) => f.name)
    const ok = await MediaService.callRoger(uploadName, {status: `Uploaded ${files.length} files.`, projectId: selectedProject.id, files})
    ok && navigate('/thank-you')
  }

  return <div className="uploader">
    <header className="uploader-header">
      <Link
            href="/"
            rel="noopener noreferrer"
            target="_blank"
            style={{ marginRight: '1em' }}>
            Start another upload
      </Link>
      <Button variant="outlined" color="default" onClick={signOut}>Sign Out</Button>
    </header>

    <Container component="main" maxWidth="sm">
      <CssBaseline />
      <Typography component="h1" variant="h5">
        Upload Site Assets
      </Typography>
      <form noValidate>
        <Autocomplete
          id="project"
          options={projects}
          value={selectedProject}
          onChange={(e, v) => setSelectedProject(v)}
          getOptionLabel={(option => `${option.name} (${option.location.name})`)}
          renderInput={(params) => <TextField {...params} label="Project" margin="normal" />}
          renderOption={(option, { inputValue }) => {
            const matches = match(`${option.name} (${option.location.name})`, inputValue);
            const parts = parse(`${option.name} (${option.location.name})`, matches);
    
            return (
              <div>
                {parts.map((part, index) => (
                  <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                    {part.text}
                  </span>
                ))}
              </div>
            );
          }}
        />
        {selectedProject &&
          <Link          
            href={`https://viewer.immersiondata.com/locations/${selectedProject.location.id}/download_dslr/hd`}
            rel="noopener noreferrer"
            target="_blank">
            Download HD CSV
          </Link>
        }
        <Autocomplete
          id="site-name"
          options={uploadCategories}
          value={uploadName}
          onChange={(e, value) => setUploadName(value == null ? '' : value)}
          // getOptionLabel={(option => `${option.name} (${option.location.name})`)}
          renderInput={(params) => <TextField {...params} label="Image group" margin="normal" />}
        />
{/* 
        <TextField
          variant="outlined"
          margin="normal"
          id="site-name"
          label="Upload Name"
          name="site-name"
          fullWidth
          required
          value={uploadName}
          error={uploadName == null || uploadName === ''}
          helperText={uploadName == null || uploadName === '' ? 'Upload Name is required.' : ''}
          onChange={({target: {value}}) => setUploadName(value == null ? '' : value)}
          inputProps={{ref: inputRef}}
          autoFocus/> */}

        <Dashboard uppy={uppy}/>

        <br/>

        <Button variant="contained" color="primary" fullWidth disabled={!uploadComplete}
          onClick={callRoger}>
          Done Uploading
        </Button>
      </form>
    </Container>
  </div>
}

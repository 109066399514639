import {fetchVoid} from './http'

type UploadNotification = {status: string, projectId: number, files: string[]}

export default (function() {
	const BASE_URL = process.env.REACT_APP_MEDIA_UPLOAD_COMPANION

	const callRoger = (siteName: string, payload: UploadNotification) => fetchVoid(`${BASE_URL}/report/${siteName}`, {
		method: 'POST',
		headers: {'Content-Type': 'application/json'},
		body: JSON.stringify(payload),
	})

	return {
		callRoger,
	}
})()